import React from 'react';
import { Link } from 'gatsby';
import { useMatch } from '@reach/router';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import Logo from './logo';
import { WHITE, LOGO_SECONDARY, LOGO_SECONDARY_LIGHT, LOGO_PRIMARY } from '../util/theme';

//******************************************************************************
const useItemStyles = makeStyles((theme) => ({
  base: {
    minWidth: 25,
    cursor: 'pointer',
    color: WHITE.css(),
    overflow: 'hidden',
    marginRight: 30,
    textDecoration: 'none', //Removes underline from <a/> element
    transition: 'color 250ms',
  },
  passive: {
    extend: 'base',
    '&:hover': {
      color: LOGO_SECONDARY_LIGHT.css(),
    }
  },
  active: {
    extend: 'base',
    color: LOGO_SECONDARY.css(),
  },
  text: {
    fontSize: 14,
    textAlign: 'right',
  },
}));
//------------------------------------------------------------------------------
const NavItem = (props) =>
{
  const classes = useItemStyles(props);
  const match = useMatch(props.match ? props.match : props.path);
  const className = match ? classes.active : classes.passive;
  return (
    <Link to={props.path}
      className={className}>
      <span className={classes.text}>{props.text}</span>
    </Link>
  );
}
//******************************************************************************
const NAV_ITEMS = [
  {path: '/', text: 'Home'},
  {path: '/products/', text: 'Products'},
  {path: '/industries/', text: 'Who Benefits'},
  {path: '/pricing/', text: 'Plans & Pricing'},
  {path: '/about/', text: 'About Us'},
];
//------------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    alignSelf: 'stretch',
    zIndex: 1000,
    backgroundColor: LOGO_PRIMARY.css(),
  },
  company: {
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    order: 0,
    position: 'sticky',
    alignItems: 'center',
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
    textDecoration: 'none',
  },
  companyLogo: {
    flex: '0 0 auto',
    marginRight: 10,
    filter: `drop-shadow(0 0 5px ${WHITE.css()})`,
    minWidth: 35,
    maxWidth: 70,
    // width: '10vw',
  },
  companyName: {
    flex: '0 0 auto',
    fontSize: 50,
    // fontStyle: 'italic',
    // fontWeight: 'bold',
    color: WHITE.css(),
  },
  spacer: {
    flex: '1 0 0',
  },
}));
//------------------------------------------------------------------------------
export function Navigation(props)
{
  const classes = useStyles(props);
  return (
    <nav className={clsx(props.className, classes.container)}>
      <Link className={classes.company} to={'/'}>
        <Logo className={classes.companyLogo}/>
        <div className={classes.companyName}>VELACTO</div>
      </Link>
      <div className={classes.spacer}/>
      {NAV_ITEMS.map((v) => <NavItem key={v.path} {...v} {...props}/>)}
    </nav>
  );
}
//------------------------------------------------------------------------------
export default Navigation;
//******************************************************************************