import { jssPreset } from '@material-ui/styles';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';

//******************************************************************************
const preset = jssPreset();
const stylesProviderProps = {
  jss: create({
    plugins: [jssExtend(), ...preset.plugins],
    insertionPoint: 'mui-inject-first',
  }),
};
//******************************************************************************
export default stylesProviderProps;