import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';

import SEO from './seo';
import { LIGHT, DARK } from '../util/theme';
import Navigation from './nav';

//******************************************************************************
const useStyles = makeStyles((theme) => ({
  main: {
    flex: '1 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    minWidth: '100%',
    minHeight: '100%',
    position: 'relative',
  },
  nav: {
    flex: '0 0 auto',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  content: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
}));
//------------------------------------------------------------------------------
const LayoutImp = (props) =>
{
  const classes = useStyles(props);
  return (
    <div className={classes.main}>
      <Navigation className={classes.nav} location={props.location} themeControl={props.themeControl}/>
      <div className={classes.content}>
        {props.children}
      </div>
    </div>
  );
}
//------------------------------------------------------------------------------
export function Layout(props)
{
  const [dark, setDark] = useState(false);
  return (
    <>
      <SEO/>
      <ThemeProvider theme={dark ? DARK : LIGHT}>
        <CssBaseline/>
        <LayoutImp {...props} themeControl={{dark, setDark}}>{props.children}</LayoutImp>
      </ThemeProvider>
    </>
  );
}
//------------------------------------------------------------------------------
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
//******************************************************************************
export default Layout
