import { createMuiTheme } from '@material-ui/core/styles';
import Chroma from 'chroma-js';
import 'typeface-roboto';

//******************************************************************************
export const BLACK = Chroma('black');
export const WHITE = Chroma('white');
export const LOGO_GREY = Chroma('#424242');
export const LOGO_PRIMARY = Chroma('#00695c');
export const LOGO_PRIMARY_LIGHT = Chroma('#439889');
export const LOGO_PRIMARY_DARK = Chroma('#003d33');
export const LOGO_SECONDARY = Chroma('#ffab40');
export const LOGO_SECONDARY_LIGHT = Chroma('#ffdd71');
export const LOGO_SECONDARY_DARK = Chroma('#c77c02');
//******************************************************************************
const LIGHT_CHROMA =
{
  logo: {
    primary: {main: LOGO_PRIMARY, dark: LOGO_PRIMARY_DARK, light: LOGO_PRIMARY_LIGHT,},
    secondary: {main: LOGO_SECONDARY, dark: LOGO_SECONDARY_DARK, light: LOGO_SECONDARY_LIGHT,},
    grey: LOGO_GREY,
  },
  nav: {
    text: {main: BLACK.alpha(0.87), active: WHITE.alpha(0.87), hover: BLACK.alpha(0.87)},
    bg: {main: WHITE, active: LOGO_PRIMARY_DARK, hover: LOGO_PRIMARY.alpha(0.3)},
    border: {main: LOGO_PRIMARY_DARK, active: LOGO_PRIMARY_DARK, hover: LOGO_PRIMARY_DARK.alpha(0.3)},
  },
  primary: {main: LOGO_PRIMARY, light: LOGO_PRIMARY_LIGHT, dark: LOGO_PRIMARY_DARK},
  secondary: {main: LOGO_PRIMARY.brighten(2), light: LOGO_PRIMARY_LIGHT.brighten(2), dark: LOGO_PRIMARY_DARK.brighten(2)},
  grey: {main: WHITE.darken(1), dark: WHITE.darken(2), light: WHITE.darken(0.5)},
  text: {primary: BLACK.alpha(0.87), secondary: BLACK.alpha(0.87), disabled: BLACK.alpha(0.38), hint: BLACK.alpha(0.38)},
  background: {paper: WHITE, default: WHITE.darken(0.2),},
};
//------------------------------------------------------------------------------
const DARK_CHROMA =
{
  logo: {
    primary: {main: LOGO_PRIMARY, dark: LOGO_PRIMARY_DARK, light: LOGO_PRIMARY_LIGHT,},
    secondary: {main: LOGO_SECONDARY, dark: LOGO_SECONDARY_DARK, light: LOGO_SECONDARY_LIGHT,},
    grey: LOGO_GREY,
  },
  nav: {
    text: {main: LOGO_SECONDARY, active: BLACK, hover: LOGO_SECONDARY},
    bg: {main: BLACK.brighten(0.5), active: LOGO_SECONDARY, hover: LOGO_SECONDARY.alpha(0.3)},
    border: {main: LOGO_SECONDARY, active: LOGO_SECONDARY, hover: LOGO_SECONDARY.alpha(0.3)},
  },
  primary: {main: LOGO_SECONDARY, light: LOGO_SECONDARY_LIGHT, dark: LOGO_SECONDARY_DARK},
  secondary: {main: LOGO_SECONDARY.darken(2), light: LOGO_SECONDARY_LIGHT.darken(2), dark: LOGO_SECONDARY_DARK.darken(2)},
  grey: {main: BLACK.brighten(1), dark: BLACK.brighten(0.5), light: BLACK.brighten(2)},
  text: {primary: LOGO_SECONDARY.alpha(0.87), secondary: LOGO_SECONDARY.alpha(0.87), disabled: LOGO_SECONDARY.alpha(0.38), hint: LOGO_SECONDARY.alpha(0.38), hightlight: BLACK},
  background: {paper: BLACK, default: BLACK.brighten(0.2),},
};
//------------------------------------------------------------------------------
const chromaToTheme = (chroma) => createMuiTheme({
  chroma,
  palette: {
    primary: {main: chroma.primary.main.css(), light: chroma.primary.light.css(), dark: chroma.primary.dark.css()},
    secondary: {main: chroma.secondary.main.css(), light: chroma.secondary.light.css(), dark: chroma.secondary.dark.css()},
    text: {primary: chroma.text.primary.css(), secondary: chroma.text.secondary.css(), disabled: chroma.text.disabled.css(), hint: chroma.text.hint.css()},
    background: {paper: chroma.background.paper.css(), default: chroma.background.default.css(),},
  },
})
//------------------------------------------------------------------------------
export const LIGHT = chromaToTheme(LIGHT_CHROMA);
export const DARK = chromaToTheme(DARK_CHROMA);
//******************************************************************************