import React from 'react';
import { useTheme } from '@material-ui/styles';

//******************************************************************************
const DEG60 = Math.PI / 3;
const R = 100;
const COS60 = Math.cos(DEG60);
const SIN60 = Math.sin(DEG60);
const POINTS_L = `${-R},0 ${R*COS60},${-R*SIN60} ${R*COS60},${R*SIN60}`;
const POINTS_R = `${R},0 ${-R*COS60},${-R*SIN60} ${-R*COS60},${R*SIN60}`;
const W = R + R * COS60;
const H = 2 * R * SIN60;
const VIEW_BOX = `${-1.25*W} ${-H} ${2.5*W} ${2*H}`;
//------------------------------------------------------------------------------
export function Logo(props)
{
  const theme = useTheme();
  const chroma = theme.chroma.logo;
  const {className} = props;
  const GREY = chroma.grey.css();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={VIEW_BOX} preserveAspectRatio='xMidYMid meet'
      className={className}>
      <defs>
        <polygon id={'triangleL'} points={POINTS_L} strokeWidth={1} stroke={GREY}/>
        <polygon id={'triangleR'} points={POINTS_R} strokeWidth={1} stroke={GREY}/>
      </defs>
      <g>
        <use fill={GREY} href="#triangleL" x={-1.75 * R * COS60} y={-.5*H}/>
        <use fill={GREY} href="#triangleR" x={1.75 * R * COS60} y={.5*H}/>

        <use fill={chroma.primary.dark.css()} href="#triangleR" x={-R - R * COS60 * .75} y={0}/>
        <use fill={chroma.secondary.dark.css()} href="#triangleL" x={R + R * COS60 * .75} y={0}/>

        <use fill={chroma.primary.main.css()} href="#triangleL" x={-1.75 * R * COS60} y={.5*H}/>
        <use fill={chroma.secondary.main.css()} href="#triangleR" x={1.75 * R * COS60} y={-.5*H}/>

        <use fill={chroma.primary.light.css()} href="#triangleR" x={-1.75 * R * COS60 + R} y={.5*H}/>
        <use fill={chroma.secondary.light.css()} href="#triangleL" x={1.75 * R * COS60 - R} y={-.5*H}/>
      </g>
    </svg>
  );
}
//------------------------------------------------------------------------------
export default Logo;
//******************************************************************************